import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '@/components/Layout';
import { PageContainer, PageHeader } from '@/components/elements';
import Seo from '@/components/Seo';
import CtaIconLinks from '@/components/CTA/CtaIconLinks';
import ServerIcon from '@/assets/svg/icon-server-colored.svg';


const LoginPage: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="Login | MyServerJapan" description="" lang="en" />
    <PageContainer>
      <PageHeader
        title="LOGIN"
        subtitle="If you already have an account with us, then you can login from here."
        bgImage={
          <StaticImage
            src="../assets/images/about-header-bg.png"
            alt=""
            quality={100}
            layout={`fixed`}
            height={300}
          />
        }
        bgColor={`#db0000`}
      />

      <CtaIconLinks
        //  bgColor={`primaryLighter`}
        //  title="Legal"
        description="Please select your server."
        items={[
          {
            id: `mirai`,
            title: `Mirai Server`,
            icon: <ServerIcon />,
            external: true,
            url: `https:/mirai.myserverjapan.com/cp`,
          },
          {
            id: `akira`,
            title: `Akira Server`,
            icon: <ServerIcon />,
            external: true,
            url: `https:/akira.myserverjapan.com/cp`,
          },
        ]}
        //  fullwidth
      />

    </PageContainer>
  </Layout>
);

export default LoginPage;
